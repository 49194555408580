const html = `<!DOCTYPE html>
<html lang="en-US">
  <head>
    <title>Eat Up - Order Adjustment Form</title>

    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="referrer" content="no-referrer-when-downgrade" />
    <!-- THIS SCRIPT NEEDS TO BE LOADED FIRST BEFORE wforms.js -->
    <script
      type="text/javascript"
      data-for="FA__DOMContentLoadedEventDispatch"
      src="https://www.tfaforms.com/js/FA__DOMContentLoadedEventDispatcher.js"
      defer
    ></script>
    <script type="text/javascript">
      document.addEventListener("FA__DOMContentLoaded", function () {
        const FORM_TIME_START = Math.floor(new Date().getTime() / 1000);
        let formElement = document.getElementById("tfa_0");
        if (null === formElement) {
          formElement = document.getElementById("0");
        }
        let appendJsTimerElement = function () {
          let formTimeDiff =
            Math.floor(new Date().getTime() / 1000) - FORM_TIME_START;
          let cumulatedTimeElement = document.getElementById(
            "tfa_dbCumulatedTime"
          );
          if (null !== cumulatedTimeElement) {
            let cumulatedTime = parseInt(cumulatedTimeElement.value);
            if (null !== cumulatedTime && cumulatedTime > 0) {
              formTimeDiff += cumulatedTime;
            }
          }
          let jsTimeInput = document.createElement("input");
          jsTimeInput.setAttribute("type", "hidden");
          jsTimeInput.setAttribute("value", formTimeDiff.toString());
          jsTimeInput.setAttribute("name", "tfa_dbElapsedJsTime");
          jsTimeInput.setAttribute("id", "tfa_dbElapsedJsTime");
          jsTimeInput.setAttribute("autocomplete", "off");
          if (null !== formElement) {
            formElement.appendChild(jsTimeInput);
          }
        };
        if (null !== formElement) {
          if (formElement.addEventListener) {
            formElement.addEventListener("submit", appendJsTimerElement, false);
          } else if (formElement.attachEvent) {
            formElement.attachEvent("onsubmit", appendJsTimerElement);
          }
        }
      });
    </script>

    <link
      href="https://www.tfaforms.com/dist/form-builder/5.0.0/wforms-layout.css?v=1682637830"
      rel="stylesheet"
      type="text/css"
    />

    <link
      href="https://www.tfaforms.com/uploads/themes/theme-92408.css"
      rel="stylesheet"
      type="text/css"
    />
    <link
      href="https://www.tfaforms.com/dist/form-builder/5.0.0/wforms-jsonly.css?v=1682637830"
      rel="alternate stylesheet"
      title="This stylesheet activated by javascript"
      type="text/css"
    />
    <script
      type="text/javascript"
      src="https://www.tfaforms.com/wForms/3.11/js/wforms.js?v=1682637830"
    ></script>
    <script type="text/javascript">
      wFORMS.behaviors.prefill.skip = false;
    </script>
    <script
      type="text/javascript"
      src="https://www.tfaforms.com/wForms/3.11/js/localization-en_US.js?v=1682637830"
    ></script>

    <script>
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-K654D6D");
    </script>
  </head>
  <body class="default wFormWebPage">
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-K654D6D"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe
    ></noscript>

    <div id="tfaContent">
      <div class="wFormContainer">
        <div class="wFormHeader"></div>
        <style type="text/css">
          #tfa_42,
          *[id^="tfa_42["] {
            width: 120px !important;
          }
          #tfa_42-D,
          *[id^="tfa_42["][class~="field-container-D"] {
            width: auto !important;
          }

          #tfa_42-L,
          label[id^="tfa_42["] {
            width: 152px !important;
            min-width: 0px;
          }

          #tfa_43,
          *[id^="tfa_43["] {
            width: 124px !important;
          }
          #tfa_43-D,
          *[id^="tfa_43["][class~="field-container-D"] {
            width: auto !important;
          }

          #tfa_43-L,
          label[id^="tfa_43["] {
            width: 239px !important;
            min-width: 0px;
          }

          #tfa_51,
          *[id^="tfa_51["] {
            width: 137px !important;
          }
          #tfa_51-D,
          *[id^="tfa_51["][class~="field-container-D"] {
            width: auto !important;
          }

          #tfa_51-L,
          label[id^="tfa_51["] {
            width: 219px !important;
            min-width: 0px;
          }
        </style>
        <div class="">
          <div class="wForm" id="5052770-WRPR" dir="ltr">
            <div class="codesection" id="code-5052770">
              <script>
                wFORMS.behaviors.paging.isConditionalSubmitAllowed = true;
                wFORMS.behaviors.condition.isConditionalSubmitAllowed = true;
              </script>
            </div>
            <h3 class="wFormTitle" data-testid="form-title" id="5052770-T">
              Eat Up - Order Adjustment Form
            </h3>
            <form
              method="post"
              action="https://www.tfaforms.com/api_v2/workflow/processor"
              class="hintsBelow labelsAbove"
              id="5052770"
              role="form"
            >
              <div id="tfa_45" class="section inline group">
                <div
                  class="oneField field-container-D wf-acl-hidden"
                  id="tfa_44-D"
                >
                  <label id="tfa_44-L" class="label preField" for="tfa_44"
                    ><b>Order Reference</b></label
                  ><br />
                  <div class="inputWrapper">
                    <input
                      type="text"
                      id="tfa_44"
                      name="tfa_44"
                      value=""
                      title="Order Reference"
                      class=""
                    />
                  </div>
                </div>
                <div class="oneField field-container-D" id="tfa_3-D">
                  <label id="tfa_3-L" class="label preField" for="tfa_3"
                    ><b>School Name</b></label
                  ><br />
                  <div class="inputWrapper">
                    <input
                      type="text"
                      id="tfa_3"
                      name="tfa_3"
                      value=""
                      title="School Name"
                      class=""
                    />
                  </div>
                </div>
              </div>
              <fieldset id="tfa_20" class="section">
                <div
                  class="oneField field-container-D"
                  id="tfa_46-D"
                  role="radiogroup"
                  aria-labelledby="tfa_46-L"
                  data-tfa-labelledby="-L tfa_46-L"
                >
                  <label id="tfa_46-L" class="label preField"
                    ><b
                      >These are the contact details we have, please confirm if
                      they are still current</b
                    ></label
                  ><br />
                  <div class="inputWrapper">
                    <table id="tfa_46" class="choices columns2">
                      <tr>
                        <td>
                          <span class="oneChoice"
                            ><input
                              type="radio"
                              value="tfa_47"
                              class=""
                              id="tfa_47"
                              name="tfa_46"
                              aria-labelledby="tfa_47-L"
                              data-tfa-labelledby="tfa_46-L tfa_47-L"
                            /><label
                              class="label postField"
                              id="tfa_47-L"
                              for="tfa_47"
                              ><span class="input-radio-faux"></span
                              ><b>Still Current</b></label
                            ></span
                          >
                        </td>
                        <td>
                          <span class="oneChoice"
                            ><input
                              type="radio"
                              value="tfa_48"
                              class=""
                              id="tfa_48"
                              name="tfa_46"
                              aria-labelledby="tfa_48-L"
                              data-tfa-labelledby="tfa_46-L tfa_48-L"
                            /><label
                              class="label postField"
                              id="tfa_48-L"
                              for="tfa_48"
                              ><span class="input-radio-faux"></span
                              ><b>Need to amend</b></label
                            ></span
                          >
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div id="tfa_49" class="section inline group">
                  <div class="oneField field-container-D" id="tfa_21-D">
                    <label id="tfa_21-L" class="label preField" for="tfa_21"
                      ><b>First Name</b></label
                    ><br />
                    <div class="inputWrapper">
                      <input
                        type="text"
                        id="tfa_21"
                        name="tfa_21"
                        value=""
                        title="First Name"
                        class=""
                      />
                    </div>
                  </div>
                  <div class="oneField field-container-D" id="tfa_22-D">
                    <label id="tfa_22-L" class="label preField" for="tfa_22"
                      ><b>Last name</b></label
                    ><br />
                    <div class="inputWrapper">
                      <input
                        type="text"
                        id="tfa_22"
                        name="tfa_22"
                        value=""
                        title="Last name"
                        class=""
                      />
                    </div>
                  </div>
                </div>
                <div id="tfa_27" class="section inline group">
                  <div class="oneField field-container-D" id="tfa_23-D">
                    <label id="tfa_23-L" class="label preField" for="tfa_23"
                      ><b>Phone</b></label
                    ><br />
                    <div class="inputWrapper">
                      <input
                        type="text"
                        id="tfa_23"
                        name="tfa_23"
                        value=""
                        title="Phone"
                        class=""
                      />
                    </div>
                  </div>
                  <div class="oneField field-container-D" id="tfa_26-D">
                    <label id="tfa_26-L" class="label preField" for="tfa_26"
                      ><b>Email</b></label
                    ><br />
                    <div class="inputWrapper">
                      <input
                        type="text"
                        id="tfa_26"
                        name="tfa_26"
                        value=""
                        title="Email"
                        class=""
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset id="tfa_41" class="section column">
                <legend id="tfa_41-L">
                  Eat Up delivers the following to your school, please amend
                  them if you want to request a different amount for the next
                  delivery. Any changes are subject to review and approval based
                  on availability.&nbsp;&nbsp;
                </legend>
                <table class="columnLayout">
                  <tr>
                    <td>
                      <div id="tfa_53" class="section inline group">
                        <div class="oneField field-container-D" id="tfa_42-D">
                          <label
                            id="tfa_42-L"
                            class="label preField"
                            for="tfa_42"
                            ><b># Sandwiches&nbsp;</b></label
                          ><br />
                          <div class="inputWrapper">
                            <input
                              type="text"
                              id="tfa_42"
                              name="tfa_42"
                              value=""
                              title="# Sandwiches "
                              class=""
                            />
                          </div>
                        </div>
                        <div class="oneField field-container-D" id="tfa_43-D">
                          <label
                            id="tfa_43-L"
                            class="label preField"
                            for="tfa_43"
                            ><b># pieces of fruit (if any)&nbsp;</b></label
                          ><br />
                          <div class="inputWrapper">
                            <input
                              type="text"
                              id="tfa_43"
                              name="tfa_43"
                              value=""
                              title="# pieces of fruit (if any) "
                              class=""
                            />
                          </div>
                        </div>
                        <div class="oneField field-container-D" id="tfa_51-D">
                          <label
                            id="tfa_51-L"
                            class="label preField"
                            for="tfa_51"
                            ><b># snack units (if any)</b></label
                          ><br />
                          <div class="inputWrapper">
                            <input
                              type="text"
                              id="tfa_51"
                              name="tfa_51"
                              value=""
                              title="# snack units (if any)"
                              class=""
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </fieldset>
              <div class="htmlSection" id="tfa_52">
                <div class="htmlContent" id="tfa_52-HTML">
                  <b>If you need to cancel the next order, please enter 0</b>
                </div>
              </div>
              <div class="oneField field-container-D" id="tfa_54-D">
                <label id="tfa_54-L" class="label preField" for="tfa_54"
                  ><b>Any Comments</b></label
                ><br />
                <div class="inputWrapper">
                  <textarea
                    id="tfa_54"
                    name="tfa_54"
                    title="Any Comments"
                    class=""
                  ></textarea>
                </div>
              </div>
              <div
                class="actions"
                id="5052770-A"
                data-contentid="submit_button"
              >
                <input
                  type="submit"
                  data-label="Submit"
                  class="primaryAction"
                  id="submit_button"
                  value="Submit"
                />
              </div>
              <div style="clear: both"></div>
              <input
                type="hidden"
                value="5052770"
                name="tfa_dbFormId"
                id="tfa_dbFormId"
              /><input
                type="hidden"
                value=""
                name="tfa_dbResponseId"
                id="tfa_dbResponseId"
              /><input
                type="hidden"
                value="e7d9cedb4ff190129fe514fd01ec6870"
                name="tfa_dbControl"
                id="tfa_dbControl"
              /><input
                type="hidden"
                value=""
                name="tfa_dbWorkflowSessionUuid"
                id="tfa_dbWorkflowSessionUuid"
              /><input
                type="hidden"
                value="8"
                name="tfa_dbVersionId"
                id="tfa_dbVersionId"
              /><input
                type="hidden"
                value=""
                name="tfa_switchedoff"
                id="tfa_switchedoff"
              />
            </form>
          </div>
        </div>
        <div class="wFormFooter">
          <p class="supportInfo">
            <a
              target="new"
              class="contactInfoLink"
              href="https://www.tfaforms.com/forms/help/5052770"
              data-testid="contact-info-link"
              >Contact Information</a
            ><br />
          </p>
        </div>
        <p class="supportInfo"></p>
      </div>
    </div>

    <script src="https://www.tfaforms.com/js/iframe_message_helper_internal.js?v=2"></script>
  </body>
</html>`;

export default html;
