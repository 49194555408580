const html = `<!DOCTYPE html>
<html lang="en-US">
  <head>
    <title>
      Let’s TalkWe would love to hear from you! To get in touch, fill out our
      enquiry form and we will get back to you as soon as possible!
    </title>

    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="referrer" content="no-referrer-when-downgrade" />
    <!-- THIS SCRIPT NEEDS TO BE LOADED FIRST BEFORE wforms.js -->
    <script
      type="text/javascript"
      data-for="FA__DOMContentLoadedEventDispatch"
      src="https://www.tfaforms.com/js/FA__DOMContentLoadedEventDispatcher.js"
      defer
    ></script>
    <script type="text/javascript">
      document.addEventListener("FA__DOMContentLoaded", function () {
        const FORM_TIME_START = Math.floor(new Date().getTime() / 1000);
        let formElement = document.getElementById("tfa_0");
        if (null === formElement) {
          formElement = document.getElementById("0");
        }
        let appendJsTimerElement = function () {
          let formTimeDiff =
            Math.floor(new Date().getTime() / 1000) - FORM_TIME_START;
          let cumulatedTimeElement = document.getElementById(
            "tfa_dbCumulatedTime"
          );
          if (null !== cumulatedTimeElement) {
            let cumulatedTime = parseInt(cumulatedTimeElement.value);
            if (null !== cumulatedTime && cumulatedTime > 0) {
              formTimeDiff += cumulatedTime;
            }
          }
          let jsTimeInput = document.createElement("input");
          jsTimeInput.setAttribute("type", "hidden");
          jsTimeInput.setAttribute("value", formTimeDiff.toString());
          jsTimeInput.setAttribute("name", "tfa_dbElapsedJsTime");
          jsTimeInput.setAttribute("id", "tfa_dbElapsedJsTime");
          jsTimeInput.setAttribute("autocomplete", "off");
          if (null !== formElement) {
            formElement.appendChild(jsTimeInput);
          }
        };
        if (null !== formElement) {
          if (formElement.addEventListener) {
            formElement.addEventListener("submit", appendJsTimerElement, false);
          } else if (formElement.attachEvent) {
            formElement.attachEvent("onsubmit", appendJsTimerElement);
          }
        }
      });
    </script>

    <link
      href="https://www.tfaforms.com/dist/form-builder/5.0.0/wforms-layout.css?v=1682637745"
      rel="stylesheet"
      type="text/css"
    />

    <link
      href="https://www.tfaforms.com/uploads/themes/theme-92403.css"
      rel="stylesheet"
      type="text/css"
    />
    <link
      href="https://www.tfaforms.com/dist/form-builder/5.0.0/wforms-jsonly.css?v=1682637745"
      rel="alternate stylesheet"
      title="This stylesheet activated by javascript"
      type="text/css"
    />
    <script
      type="text/javascript"
      src="https://www.tfaforms.com/wForms/3.11/js/wforms.js?v=1682637745"
    ></script>
    <script type="text/javascript">
      wFORMS.behaviors.prefill.skip = false;
    </script>
    <script
      type="text/javascript"
      src="https://www.tfaforms.com/wForms/3.11/js/localization-en_US.js?v=1682637745"
    ></script>

    <script>
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-K654D6D");
    </script>
  </head>
  <body class="default wFormWebPage">
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-K654D6D"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe
    ></noscript>

    <div id="tfaContent">
      <div class="wFormContainer">
        <div class="wFormHeader"></div>
        <style type="text/css">
          #tfa_1,
          *[id^="tfa_1["] {
            width: 272px !important;
          }
          #tfa_1-D,
          *[id^="tfa_1["][class~="field-container-D"] {
            width: auto !important;
          }

          #tfa_2,
          *[id^="tfa_2["] {
            width: 272px !important;
          }
          #tfa_2-D,
          *[id^="tfa_2["][class~="field-container-D"] {
            width: auto !important;
          }

          #tfa_3,
          *[id^="tfa_3["] {
            width: 272px !important;
          }
          #tfa_3-D,
          *[id^="tfa_3["][class~="field-container-D"] {
            width: auto !important;
          }

          #tfa_4,
          *[id^="tfa_4["] {
            width: 272px !important;
          }
          #tfa_4-D,
          *[id^="tfa_4["][class~="field-container-D"] {
            width: auto !important;
          }

          #tfa_20,
          *[id^="tfa_20["] {
            width: 565px !important;
          }
          #tfa_20-D,
          *[id^="tfa_20["][class~="field-container-D"] {
            width: auto !important;
          }

          #tfa_7,
          *[id^="tfa_7["] {
            width: 565px !important;
          }
          #tfa_7-D,
          *[id^="tfa_7["][class~="field-container-D"] {
            width: auto !important;
          }

          #tfa_15,
          *[id^="tfa_15["] {
            width: 565px !important;
          }
          #tfa_15-D,
          *[id^="tfa_15["][class~="field-container-D"] {
            width: auto !important;
          }

          #tfa_16,
          *[id^="tfa_16["] {
            width: 565px !important;
          }
          #tfa_16-D,
          *[id^="tfa_16["][class~="field-container-D"] {
            width: auto !important;
          }

          #tfa_14,
          *[id^="tfa_14["] {
            width: 565px !important;
          }
          #tfa_14-D,
          *[id^="tfa_14["][class~="field-container-D"] {
            width: auto !important;
          }

          #tfa_14,
          *[id^="tfa_14["] {
            height: 100px !important;
          }
          #tfa_14-D,
          *[id^="tfa_14["][class~="field-container-D"] {
            height: auto !important;
          }
          #tfa_14-L,
          label[id^="tfa_14["],
          *[id^="tfa_14["][id$="-L"] {
            height: auto !important;
          }
        </style>
        <div class="">
          <div class="wForm" id="5051010-WRPR" dir="ltr">
            <div class="codesection" id="code-5051010">
              <script>
                wFORMS.behaviors.paging.isConditionalSubmitAllowed = true;
                wFORMS.behaviors.condition.isConditionalSubmitAllowed = true;
              </script>
            </div>
            <h3 class="wFormTitle" data-testid="form-title" id="5051010-T">
              <span
                style="
                  background-color: rgb(212, 185, 253);
                  font-family: Boing, ui-sans-serif, system-ui, -apple-system,
                    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
                    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
                    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
                  font-size: 70px;
                  font-weight: inherit;
                  letter-spacing: -0.01em;
                "
                >Let’s Talk</span
              ><br />
              <p
                class="css-1f5sh81"
                style="
                  border-width: 0px;
                  border-style: solid;
                  border-color: currentcolor;
                  --tw-translate-x: 0;
                  --tw-translate-y: 0;
                  --tw-rotate: 0;
                  --tw-skew-x: 0;
                  --tw-skew-y: 0;
                  --tw-scale-x: 1;
                  --tw-scale-y: 1;
                  --tw-transform: translateX(var(--tw-translate-x))
                    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
                    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
                    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                  --tw-ring-inset: var(--tw-empty);
                  --tw-ring-offset-width: 0px;
                  --tw-ring-offset-color: #fff;
                  --tw-ring-color: rgba(59, 130, 246, 0.5);
                  --tw-ring-offset-shadow: 0 0 #0000;
                  --tw-ring-shadow: 0 0 #0000;
                  --tw-shadow: 0 0 #0000;
                  --tw-blur: var(--tw-empty);
                  --tw-brightness: var(--tw-empty);
                  --tw-contrast: var(--tw-empty);
                  --tw-grayscale: var(--tw-empty);
                  --tw-hue-rotate: var(--tw-empty);
                  --tw-invert: var(--tw-empty);
                  --tw-saturate: var(--tw-empty);
                  --tw-sepia: var(--tw-empty);
                  --tw-drop-shadow: var(--tw-empty);
                  --tw-filter: var(--tw-blur) var(--tw-brightness)
                    var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
                    var(--tw-invert) var(--tw-saturate) var(--tw-sepia)
                    var(--tw-drop-shadow);
                  --tw-backdrop-blur: var(--tw-empty);
                  --tw-backdrop-brightness: var(--tw-empty);
                  --tw-backdrop-contrast: var(--tw-empty);
                  --tw-backdrop-grayscale: var(--tw-empty);
                  --tw-backdrop-hue-rotate: var(--tw-empty);
                  --tw-backdrop-invert: var(--tw-empty);
                  --tw-backdrop-opacity: var(--tw-empty);
                  --tw-backdrop-saturate: var(--tw-empty);
                  --tw-backdrop-sepia: var(--tw-empty);
                  --tw-backdrop-filter: var(--tw-backdrop-blur)
                    var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
                    var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
                    var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
                    var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
                  font-family: Boing, ui-sans-serif, system-ui, -apple-system,
                    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
                    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
                    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
                  font-size: 34px;
                  line-height: 40.8px;
                  font-weight: 400;
                  background-color: rgb(212, 185, 253);
                "
              >
                We would love to hear from you! To get in touch, fill out our
                enquiry form and we will get back to you as soon as possible!
              </p>
            </h3>
            <form
              method="post"
              action="https://www.tfaforms.com/api_v2/workflow/processor"
              class="labelsLeftAligned"
              id="5051010"
              role="form"
            >
              <div id="tfa_32" class="section group">
                <label class="label preField" id="tfa_32-L"
                  ><b>Your Details</b></label
                >
                <div id="tfa_33" class="section inline group">
                  <div
                    class="oneField field-container-D labelsAbove"
                    id="tfa_1-D"
                  >
                    <label
                      id="tfa_1-L"
                      class="label preField reqMark"
                      for="tfa_1"
                      >First Name</label
                    ><br />
                    <div class="inputWrapper">
                      <input
                        aria-required="true"
                        type="text"
                        id="tfa_1"
                        name="tfa_1"
                        value=""
                        title="First Name"
                        class="required"
                      />
                    </div>
                  </div>
                  <div
                    class="oneField field-container-D labelsAbove"
                    id="tfa_2-D"
                  >
                    <label
                      id="tfa_2-L"
                      class="label preField reqMark"
                      for="tfa_2"
                      >Last Name</label
                    ><br />
                    <div class="inputWrapper">
                      <input
                        aria-required="true"
                        type="text"
                        id="tfa_2"
                        name="tfa_2"
                        value=""
                        title="Last Name"
                        class="required"
                      />
                    </div>
                  </div>
                </div>
                <div id="tfa_34" class="section inline group">
                  <div
                    class="oneField field-container-D labelsAbove"
                    id="tfa_3-D"
                  >
                    <label
                      id="tfa_3-L"
                      class="label preField reqMark"
                      for="tfa_3"
                      >Email</label
                    ><br />
                    <div class="inputWrapper">
                      <input
                        aria-required="true"
                        type="text"
                        id="tfa_3"
                        name="tfa_3"
                        value=""
                        title="Email"
                        class="required"
                      />
                    </div>
                  </div>
                  <div
                    class="oneField field-container-D labelsAbove"
                    id="tfa_4-D"
                  >
                    <label id="tfa_4-L" class="label preField" for="tfa_4"
                      >Phone</label
                    ><br />
                    <div class="inputWrapper">
                      <input
                        type="text"
                        id="tfa_4"
                        name="tfa_4"
                        value=""
                        title="Phone"
                        class=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="oneField field-container-D labelsAbove"
                  id="tfa_20-D"
                >
                  <label
                    id="tfa_20-L"
                    class="label preField reqMark"
                    for="tfa_20"
                    >Your Location</label
                  ><br />
                  <div class="inputWrapper">
                    <select
                      aria-required="true"
                      id="tfa_20"
                      name="tfa_20"
                      title="Your Location"
                      class="required"
                    >
                      <option value="">Please select...</option>
                      <option value="tfa_21" id="tfa_21" class="">NSW</option>
                      <option value="tfa_22" id="tfa_22" class="">NT</option>
                      <option value="tfa_23" id="tfa_23" class="">QLD</option>
                      <option value="tfa_24" id="tfa_24" class="">VIC</option>
                      <option value="tfa_25" id="tfa_25" class="">WA</option>
                      <option value="tfa_26" id="tfa_26" class="">
                        ACT, SA, TAS - Not currently servicing, but would love
                        to hear from you!
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="oneField field-container-D labelsAbove"
                  id="tfa_7-D"
                >
                  <label id="tfa_7-L" class="label preField reqMark" for="tfa_7"
                    >Enquiry Type</label
                  ><br />
                  <div class="inputWrapper">
                    <select
                      aria-required="true"
                      id="tfa_7"
                      name="tfa_7"
                      title="Enquiry Type"
                      class="required"
                    >
                      <option value="">Please select...</option>
                      <option value="tfa_8" id="tfa_8" class="">
                        I am an individual wanting to volunteer
                      </option>
                      <option
                        value="tfa_9"
                        id="tfa_9"
                        data-conditionals="#tfa_15"
                        class=""
                      >
                        I want to organise a session at my workplace
                      </option>
                      <option
                        value="tfa_10"
                        id="tfa_10"
                        data-conditionals="#tfa_16"
                        class=""
                      >
                        I want to organise a session at my school
                      </option>
                      <option
                        value="tfa_11"
                        id="tfa_11"
                        data-conditionals="#tfa_15"
                        class=""
                      >
                        I want to fundraise for your cause
                      </option>
                      <option
                        value="tfa_12"
                        id="tfa_12"
                        data-conditionals="#tfa_16"
                        class=""
                      >
                        My school/ a school near me needs support
                      </option>
                      <option value="tfa_13" id="tfa_13" class="">Other</option>
                    </select>
                  </div>
                </div>
                <div id="tfa_35" class="section inline group">
                  <div
                    class="oneField field-container-D labelsAbove"
                    id="tfa_15-D"
                  >
                    <label id="tfa_15-L" class="label preField" for="tfa_15"
                      >Company</label
                    ><br />
                    <div class="inputWrapper">
                      <input
                        type="text"
                        id="tfa_15"
                        name="tfa_15"
                        value=""
                        data-condition="\`#tfa_9\` OR \`#tfa_11\`"
                        title="Company"
                        class=""
                      />
                    </div>
                  </div>
                  <div
                    class="oneField field-container-D labelsAbove"
                    id="tfa_16-D"
                  >
                    <label id="tfa_16-L" class="label preField" for="tfa_16"
                      >School Name</label
                    ><br />
                    <div class="inputWrapper">
                      <input
                        type="text"
                        id="tfa_16"
                        name="tfa_16"
                        value=""
                        data-condition="\`#tfa_10\` OR \`#tfa_12\`"
                        title="School Name"
                        class=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="tfa_36" class="section group">
                <label class="label preField" id="tfa_36-L"
                  ><b>Your Message</b></label
                >
                <div
                  class="oneField field-container-D labelsAbove"
                  id="tfa_14-D"
                >
                  <label id="tfa_14-L" class="label preField" for="tfa_14"
                    >Type message here...</label
                  ><br />
                  <div class="inputWrapper">
                    <textarea
                      id="tfa_14"
                      name="tfa_14"
                      title="Type message here..."
                      class=""
                    ></textarea>
                  </div>
                </div>
              </div>
              <div id="tfa_31" class="section inline group">
                <div
                  class="oneField field-container-D labelsRemoved"
                  id="tfa_28-D"
                  role="group"
                  aria-labelledby="tfa_28-L"
                  data-tfa-labelledby="-L tfa_28-L"
                >
                  <div class="inputWrapper">
                    <span id="tfa_28" class="choices vertical"
                      ><span class="oneChoice"
                        ><input
                          type="checkbox"
                          value="tfa_29"
                          class=""
                          checked
                          data-default-value="true"
                          id="tfa_29"
                          name="tfa_29"
                          aria-labelledby="tfa_29-L"
                          data-tfa-labelledby="tfa_28-L tfa_29-L"
                        /><label
                          class="label postField"
                          id="tfa_29-L"
                          for="tfa_29"
                          ><span class="input-checkbox-faux"></span>I agree to
                          receiving newsletter from Eat Up</label
                        ></span
                      ></span
                    >
                  </div>
                </div>
                <div class="htmlSection" id="tfa_30">
                  <div class="htmlContent" id="tfa_30-HTML">
                    <b
                      ><u
                        ><a
                          target="_blank"
                          href="https://www.eatup.org.au/privacy-policy"
                          >Privacy Policy</a
                        ></u
                      ></b
                    >
                  </div>
                </div>
              </div>
              <div
                class="actions"
                id="5051010-A"
                data-contentid="submit_button"
              >
                <input
                  type="submit"
                  data-tfa_9="Submit"
                  class="primaryAction"
                  id="submit_button"
                  value="Submit"
                />
              </div>
              <div style="clear: both"></div>
              <input
                type="hidden"
                value="5051010"
                name="tfa_dbFormId"
                id="tfa_dbFormId"
              /><input
                type="hidden"
                value=""
                name="tfa_dbResponseId"
                id="tfa_dbResponseId"
              /><input
                type="hidden"
                value="da57584e57f3d4e8d4f9dc5e8134398a"
                name="tfa_dbControl"
                id="tfa_dbControl"
              /><input
                type="hidden"
                value=""
                name="tfa_dbWorkflowSessionUuid"
                id="tfa_dbWorkflowSessionUuid"
              /><input
                type="hidden"
                value="16"
                name="tfa_dbVersionId"
                id="tfa_dbVersionId"
              /><input
                type="hidden"
                value=""
                name="tfa_switchedoff"
                id="tfa_switchedoff"
              />
            </form>
          </div>
        </div>
        <div class="wFormFooter">
          <p class="supportInfo"><br /></p>
        </div>
        <p class="supportInfo"></p>
      </div>
    </div>

    <script src="https://www.tfaforms.com/js/iframe_message_helper_internal.js?v=2"></script>
  </body>
</html>
`; export default html;
