/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import Footer from "~components/Footer.jsx";
import Layout from "~components/Layout.jsx";
import SEO from "~components/SEO.jsx";

import extraorderForm from "~data/forms/extraorder.html";
import newletstalkForm from "~data/forms/newletstalk.html";
import newschoolregistrationForm from "~data/forms/newschoolregistration.html";
import orderupdateForm from "~data/forms/orderupdate.html";

const TestFormsPage = ({ data, location }) => {
  // Fetch the HTML content from the separate file
  const fetchHTMLContent = async (form) => {
    try {
      const response = await fetch(form);
      const htmlContent = await response.text();
      return htmlContent;
    } catch (error) {
      console.error("Error loading HTML content:", error);
    }
  };

  // Render the iframe with the loaded HTML content
  const renderIframe = (form) => {
    const htmlContent = fetchHTMLContent(form);

    return (
      <iframe
        title="Extra Order"
        srcDoc={extraorderForm}
        style={{ width: "100%", height: "500px" }}
      ></iframe>
    );
  };

  return (
    <>
      <SEO noIndex />

      <Layout>
        <iframe
          title="Extra Order"
          srcDoc={extraorderForm}
          style={{ width: "100%", height: "1500px" }}
        ></iframe>

        <iframe
          title="New Let's Talk"
          srcDoc={newletstalkForm}
          style={{ width: "100%", height: "1500px" }}
        ></iframe>

        <iframe
          title="New School Registration"
          srcDoc={newschoolregistrationForm}
          style={{ width: "100%", height: "3000px" }}
        ></iframe>

        <iframe
          title="Order Update"
          srcDoc={orderupdateForm}
          style={{ width: "100%", height: "1500px" }}
        ></iframe>
      </Layout>

      <Footer />
    </>
  );
};

export default TestFormsPage;
